import { useNavigate } from "react-router-dom";
import TagsContainer from "../Components/Containers/TagsContainer";
import CustomButton from "../Components/CustomButton";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <TagsContainer>
        <CustomButton
          title="Voir les établissements"
          onClick={() => navigate("/listPlaces")}
        />
        <CustomButton
          title="Voir les kits"
          onClick={() => navigate("/listKits")}
        />
        <CustomButton
          title="Voir les puces"
          onClick={() => navigate("/listTags")}
        />
      </TagsContainer>
    </>
  );
}
