import { Typography } from "@mui/material";
import CenteredDivider from "./CenteredDivider";
import React from "react";

export default function Title({ title = "", variant = "h2", align = "left" }) {
  return (
    <>
      <Typography variant={variant} color="primary" align={align} marginTop={2}>
        {title}
      </Typography>
    </>
  );
}
