import styled from "@emotion/styled";
import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CenteredDivider from "../../Components/CenteredDivider";
import ScheduleContainer from "../../Components/Containers/ScheduleContainer";
import ScheduleItemContainer from "../../Components/Containers/ScheduleItemContainer";
import CustomButton from "../../Components/CustomButton";
import Tag from "../../Components/Tag";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import { debounce } from "../../Utils/Debounce";
import FilterKitsByState from "../../Utils/FilterKitsByState";
import GroupByDate, { maxDate } from "../../Utils/groupByDate";
import { KITS_STATES } from "../../Utils/KitsStateFetch";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function ProDashboard() {
  const { firebase } = useFirebase();
  const [establishmentData, setEstablishmentData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [allTagsHistory, setAllTagsHistory] = useState();
  const [schedule, setSchedule] = useState();
  const [establishmentName, setEstablishmentName] = useState();
  useEffect(() => {
    (async () => {
      try {
        const id = sessionStorage.getItem("establishment");
        const placeRef = doc(firebase.db, `places/${id}`);
        const placeData = await getDoc(placeRef);

        setEstablishmentName(placeData.data().name);

        const qHistory = query(
          collectionGroup(firebase.db, "history"),
          where("placeId", "==", id),
          where("state", "in", KITS_STATES),
          where("updatedAt", ">=", new Date(maxDate)),
          orderBy("updatedAt", "desc"),
          limit(250)
        );

        const history = [];
        const historyQuerySnapshot = await getDocs(qHistory);
        historyQuerySnapshot.forEach((tag) =>
          history.push({
            versionId: tag.id,
            id: tag.ref.parent.parent.id,
            ...tag.data(),
          })
        );
        const filteredHistory = FilterKitsByState(history);
        setAllTagsHistory(filteredHistory);
        const formatedHistory = GroupByDate(filteredHistory);
        setSchedule(formatedHistory);

        // setAllTagsHistory(history);
        // const formatedHistory = GroupByDate(history);
        // setSchedule(formatedHistory);

        const servicesRef = collection(firebase.db, "services");
        const servicesQuery = query(
          servicesRef,
          where("place", "==", id),
          orderBy("name", "asc")
        );
        const servicesSnapshot = await getDocs(servicesQuery);
        let services = [];
        for (const service of servicesSnapshot.docs) {
          let rooms = [];
          const roomsRef = collection(firebase.db, "places");
          const roomsQuery = query(
            roomsRef,
            where("service", "==", service.id),
            orderBy("name", "asc")
          );
          const roomsSnapshot = await getDocs(roomsQuery);
          roomsSnapshot.forEach((room) => {
            rooms.push({
              id: room.id,
              ...room.data(),
              serviceName: service.data().name,
            });
          });
          rooms.sort((a, b) => a.name.localeCompare(b.name));
          const data = {
            id: service.id,
            ...service.data(),
            rooms: rooms,
          };
          services.push(data);
        }
        const establishment = { place: placeData.data(), services: services };

        setEstablishmentData(establishment);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const filterRooms = debounce((e) => {
    const { value } = e.target;
    let temp = [];
    if (!value) {
      setSchedule(GroupByDate(allTagsHistory));
      return;
    }
    allTagsHistory.forEach(
      (tag) =>
        tag?.roomName?.toLowerCase()?.includes(value?.toLowerCase()) &&
        temp.push(tag)
    );
    setSchedule(GroupByDate(temp));
  });

  const handleNavigation = (id, name) =>
    navigate("/pro/showRoom", {
      state: {
        id: id,
        name: name,
      },
    });
  return (
    <>
      {!loading ? (
        <>
          {establishmentData && (
            <>
              <Title title={establishmentName} align="center" />
              <CenteredDivider />
              <TextField
                label="Rechercher une chambre"
                variant="outlined"
                style={{
                  margin: "auto",
                  display: "flex",
                  width: "90%",
                  marginTop: 15,
                }}
                onChange={filterRooms}
              />
              {schedule &&
                schedule.map((e, index) => {
                  const date = [e][0][0];
                  const data = [e][0][1];

                  const formatedDate = dayjs(date).format("DD/MM/YYYY");

                  return (
                    <ScheduleContainer key={`schedule-container-${index}`}>
                      <Typography variant="h5" color="primary">
                        {formatedDate}
                      </Typography>
                      {data.length ? (
                        <>
                          <CenteredDivider />
                          <ScheduleItemContainer>
                            {data.map((item) => (
                              <Tag
                                tag={item}
                                key={item.versionId}
                                tagType="pro"
                              />
                            ))}
                          </ScheduleItemContainer>
                        </>
                      ) : (
                        ""
                      )}
                    </ScheduleContainer>
                  );
                })}

              {establishmentData &&
                establishmentData.services.map((service) => (
                  <div
                    style={{ width: "90%", margin: "auto" }}
                    key={service.id}
                  >
                    <Row>
                      <div>
                        <Title title={service.name} variant="h4" />
                      </div>
                      {/* <div>
                      <EditAlert />
                      <CustomButton
                        color="secondary"
                        fullWidth={false}
                        title={<Delete />}
                        onClick={() => console.log("delete")}
                      />
                    </div> */}
                    </Row>
                    <CenteredDivider />
                    {service.rooms.length > 0 &&
                      service.rooms.map((room) => (
                        <React.Fragment key={room.id}>
                          <Row>
                            <div>
                              <Button
                                onClick={() =>
                                  handleNavigation(room.id, room.name)
                                }
                              >
                                <Title title={room.name} variant="h5" />
                              </Button>
                            </div>
                            <div>
                              <CustomButton
                                fullWidth={false}
                                title="Accèder à la chambre"
                                onClick={() =>
                                  handleNavigation(room.id, room.name)
                                }
                              />
                              {/* <EditAlert
                              fn={editRoomName}
                              id={room.id}
                              name={room.name}
                              title="Voulez vous modifier le nom de la chambre ?"
                            />
                            <DeleteAlert
                              fn={deleteRoom}
                              id={room.id}
                              name={room.name}
                              title="Voulez vous supprimer la chambre ?"
                            /> */}
                            </div>
                          </Row>
                        </React.Fragment>
                      ))}
                  </div>
                ))}
            </>
          )}
        </>
      ) : (
        <LinearProgress style={{ marginBlock: 20 }} />
      )}
    </>
  );
}
