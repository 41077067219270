export default function FilterKitsByState(kits) {
  kits.sort((a, b) => a.id.localeCompare(b.id));

  for (let i = 0; i < kits.length - 1; i++) {
    const currentId = kits[i].id;
    const nextId = kits[i + 1].id;
    const currentState = kits[i].state;
    const nextState = kits[i + 1].state;
    const nextSeen = kits[i + 1].seen === true;
    const statesConditions =
      currentState === "Réceptionné" ||
      currentState === "Déposé au laboratoire";
    if (statesConditions && currentId === nextId) {
      if (nextState === "Prélevé") {
        if (nextSeen) {
          kits[i].seen = true;
        }
        kits.splice(i + 1, 1);
      }
    }
  }

  kits.sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds);
  return kits;
}
