import dayjs from "dayjs";

let currentMinDate = new Date();
let currentMaxDate = new Date();
currentMaxDate.setDate(currentMaxDate.getDate() - 15);
export const maxDate = currentMaxDate;
let formatedMinDate = dayjs(currentMinDate).format("YYYY-MM-DD");
let formatedMaxDate = dayjs(currentMaxDate).format("YYYY-MM-DD");

export default function GroupByDate(data) {
  const map = new Map();
  const date = new Date(formatedMinDate);
  const endDate = new Date(formatedMaxDate);
  while (date >= endDate) {
    map.set(dayjs(date).format("YYYY-MM-DD"), []);
    date.setDate(date.getDate() - 1);
  }
  if (data === null) {
    return Array.from(map);
  }
  data.forEach((item) => {
    const itemTime = dayjs.unix(item.updatedAt.seconds).format("YYYY-MM-DD");
    map.get(itemTime).push(item);
  });
  return Array.from(map);
}
