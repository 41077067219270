import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ListKits from "./Pages/Kit/ListKits";
import ListTags from "./Pages/Tag/ListTags";
import Wrapper from "./Components/Layouts/Wrapper";
import TagHistory from "./Pages/Tag/TagHistory";
import ListPlaces from "./Pages/Place/ListPlaces";
import ShowPlace from "./Pages/Place/ShowPlace";
import ShowRoom from "./Pages/Room/ShowRoom";
import { decode } from "./Utils/Encode";
import ProDashboard from "./Pages/Dashboard/ProDashboard";
import ProShowRoom from "./Pages/Room/ProShowRoom";
import ProKitHistory from "./Pages/Kit/ProKitHistory";
import ExportKits from "./Pages/Kit/ExportKits";
import CreateUser from "./Pages/User/CreateUser";
import TechDashboard from "./Pages/Dashboard/TechDashboard";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        {sessionStorage.status ? (
          <>
            <Route element={<Wrapper />}>
              {decode(sessionStorage.status) === "pro" && (
                <>
                  <Route path="/pro/dashboard" element={<ProDashboard />} />
                  <Route path="/pro/showRoom" element={<ProShowRoom />} />
                  <Route path="/pro/kitHistory" element={<ProKitHistory />} />
                </>
              )}
              {decode(sessionStorage.status) === "admin" && (
                <>
                  <Route path="/home" element={<Home />} />
                  <Route path="/listPlaces" element={<ListPlaces />} />
                  <Route path="/showPlace" element={<ShowPlace />} />
                  <Route path="/listKits" element={<ListKits />} />
                  <Route path="/listTags" element={<ListTags />} />
                  <Route path="/tagHistory" element={<TagHistory />} />
                  <Route path="/showRoom" element={<ShowRoom />} />
                  <Route path="/createUser" element={<CreateUser />} />
                  <Route path="/exportKits" element={<ExportKits />} />
                </>
              )}
              {decode(sessionStorage.status) === "secretary" && (
                <>
                  <Route path="/pro/dashboard" element={<ProDashboard />} />
                  <Route path="/pro/showRoom" element={<ProShowRoom />} />
                  <Route path="/pro/kitHistory" element={<ProKitHistory />} />
                  <Route path="/exportKits" element={<ExportKits />} />
                </>
              )}
              {decode(sessionStorage.status) === "tech" && (
                <>
                  <Route path="/tech/dashboard" element={<TechDashboard />} />
                </>
              )}
            </Route>
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
