import { LinearProgress } from "@mui/material";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import TagsContainer from "../../Components/Containers/TagsContainer";
import Tag from "../../Components/Tag";
import { useFirebase } from "../../Firebase/FirebaseContext";
import Title from "../../Components/Title";
import CenteredDivider from "../../Components/CenteredDivider";

export default function ListKits() {
  const { firebase } = useFirebase();
  const [kits, setKits] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const tagsRef = collection(firebase.db, "tags");
        const q = query(
          tagsRef,
          where("type", "==", "kit"),
          orderBy("updatedAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        let kitsArr = [];
        for (const doc of querySnapshot.docs) {
          if (doc.data().state === "Déposé au laboratoire") {
            const historyRef = collection(
              firebase.db,
              `tagsHistory/${doc.id}/history`
            );
            const historySnapshot = query(
              historyRef,
              where("updatedAt", "==", doc.data().updatedAt)
            );
            const queryResult = await getDocs(historySnapshot);
            queryResult.forEach((result) => {
              kitsArr.push({ id: doc.id, ...doc.data(), versionId: result.id });
            });
          } else {
            kitsArr.push({ id: doc.id, ...doc.data() });
          }
        }
        setKits(kitsArr);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Title title="Liste des kits" align="center" />

      <CenteredDivider />
      {!loading ? (
        <TagsContainer>
          {kits.length > 0 &&
            kits.map((kit) => (
              <Tag tag={kit} key={`kit-uid-${kit.id}`} exportTag={false} />
            ))}
        </TagsContainer>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
