import styled from "@emotion/styled";
import dayjs from "dayjs";
import {
  collection,
  collectionGroup,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import CenteredDivider from "../../Components/CenteredDivider";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";

const Row = styled.div`
  display: flex;
  justify-content: space-around;
`;

export default function TechDashboard() {
  const { firebase } = useFirebase();
  const [awaitingDispatchNumber, setAwaitingDispatchNumber] = useState();
  const [pickedUpNumber, setPickedUpNumber] = useState();

  useEffect(() => {
    const now = dayjs().format("YYYY-MM-DD");
    const today = new Date(now);
    (async () => {
      const id = sessionStorage.getItem("establishment");
      try {
        const qHistory = query(
          collectionGroup(firebase.db, "history"),
          where("placeId", "==", id),
          where("state", "==", "Réceptionné"),
          where("updatedAt", ">=", today),
          orderBy("updatedAt", "desc")
        );
        const qHistorySnapshot = await getDocs(qHistory);
        // console.log(qHistorySnapshot.size);
        setPickedUpNumber(qHistorySnapshot.size);

        const tagsRef = collection(firebase.db, "tags");
        const q = query(
          tagsRef,
          where("placeId", "==", id),
          where("state", "==", "Prélevé"),
          where("updatedAt", ">=", today)
        );
        const qSnapshot = await getDocs(q);
        // console.log(qSnapshot.size);
        setAwaitingDispatchNumber(qSnapshot.size);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Title title="Bilan de l'établissement" align="center" />
      <CenteredDivider />
      <Row>
        <Title variant="h5" title={`Bilans réceptionnés : ${pickedUpNumber}`} />
        <Title
          variant="h5"
          title={`Bilans prélevés, en attente de réception : ${awaitingDispatchNumber}`}
        />
      </Row>
    </>
  );
}
