import { ThemeProvider } from "@mui/material";
import FirebaseContextProvider from "./Firebase/FirebaseContext";
import Router from "./Router";
import { theme } from "./Theme";

function App() {
  return (
    <FirebaseContextProvider>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </FirebaseContextProvider>
  );
}

export default App;
