import React, { useState } from "react";
import { Avatar, CssBaseline, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useFirebase } from "../Firebase/FirebaseContext";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CustomTextInput from "../Components/Inputs/CustomTextInput";
import styled from "@emotion/styled";
import { Navigate, useNavigate } from "react-router-dom";
import CustomButton from "../Components/CustomButton";
import { collection, getDocs, query, where } from "firebase/firestore";
import { decode, encode } from "../Utils/Encode";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CenterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
`;
export default function Login() {
  const { firebase } = useFirebase();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const signIn = async () => {
    ;
    try {
      await setPersistence(firebase.auth, browserSessionPersistence);
      await signInWithEmailAndPassword(firebase.auth, email, password);
      const usersRef = collection(firebase.db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        if (doc.data().status === "admin") {
          sessionStorage.setItem("status", encode(doc.data().status));
          window.location.reload();
        } else if (doc.data().status === "pro") {
          sessionStorage.setItem("status", encode(doc.data().status));
          sessionStorage.setItem("establishment", doc.data().belongsTo);
          window.location.reload();
        } else if (doc.data().status === "secretary") {
          sessionStorage.setItem("status", encode(doc.data().status));
          sessionStorage.setItem("establishment", doc.data().belongsTo);
          window.location.reload();
        } else if (doc.data().status === "tech") {
          sessionStorage.setItem("status", encode(doc.data().status));
          sessionStorage.setItem("establishment", doc.data().belongsTo);
          window.location.reload();
        } else {
          sessionStorage.clear();
        }
      });
    } catch (e) {
      console.log(e);
      setOpen(true)
    }
  };

  return (
    <CenterContainer>
      {sessionStorage.status && decode(sessionStorage.status) === "admin" && (
        <Navigate to="/home" replace />
      )}
      {sessionStorage.establishment &&
        sessionStorage.status &&
        decode(sessionStorage.status) === "pro" && (
          <Navigate to="/pro/dashboard" replace />
        )}
      {sessionStorage.establishment &&
        sessionStorage.status &&
        decode(sessionStorage.status) === "secretary" && (
          <Navigate to="/pro/dashboard" replace />
        )}
      {sessionStorage.establishment &&
        sessionStorage.status &&
        decode(sessionStorage.status) === "tech" && (
          <Navigate to="/tech/dashboard" replace />
        )}
      <CssBaseline />
      <FormContainer>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <CustomTextInput label="Email" setState={setEmail} autoFocus={true} />
        <CustomTextInput
          label="Password"
          setState={setPassword}
          type="password"
        />
        <CustomButton title="Connexion" onClick={() => signIn()} />

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            key={"top" + "right"}
          >  Erreur. adresse mail ou mot de passe incorrect 
          </Alert>
        </Snackbar>
      </FormContainer>
    </CenterContainer>
  );
}
