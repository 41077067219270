import styled from "@emotion/styled";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import CustomTextInput from "../../Components/Inputs/CustomTextInput";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import ExpressAPI from "../../Utils/ExpressAPI";

const STATUSES = [
  { value: "admin", label: "Administrateur" },
  { value: "courier", label: "Coursier" },
  { value: "ide", label: "Infirmier (hors clinique)" },
  { value: "pro", label: "Professionnel" },
  { value: "secretary", label: "Secrétaire" },
  { value: "tech", label: "Technique" },
];
const Wrapper = styled(Paper)`
  width: 75%;
  margin: auto;
  padding: 20px;
`;
export default function CreateUser() {
  const { firebase } = useFirebase();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [status, setStatus] = useState(STATUSES[0].value);
  const [establishment, setEstablishment] = useState();
  const [establishments, setEstablishments] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      const placesRef = collection(firebase.db, "places");
      let types = ["Clinique"];
      const q = query(placesRef, where("type", "in", types));
      const placesDocs = await getDocs(q);
      let places = [];
      placesDocs.forEach((place) =>
        places.push({ id: place.id, ...place.data() })
      );
      setEstablishments(places);
      console.log(places);
    })();
  }, []);
  const submit = async () => {
    const data = {
      name: name,
      email: email,
      password: password,
      status: status,
      createdAt: new Date(),
      establishment: establishment,
    };
    console.log("click");
    if (name && email && password && status) {
      if (
        (status === "pro" || status === "secretary" || status === "tech") &&
        !establishment
      ) {
        return;
      }
      try {
        setLoading(true);
        const req = await ExpressAPI("POST", "auth/createUser", data);
        setLoading(false);
        navigate("/home");
        console.log(req);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };

  return (
    <>
      <Title title="Créer un utilisateur" align="center" />
      <Wrapper>
        <CustomTextInput label="Nom" setState={setName} />
        <CustomTextInput label="Email" setState={setEmail} />
        <CustomTextInput
          label="Mot de passe"
          setState={setPassword}
          type="password"
        />
        <FormControl>
          <FormLabel>Statut</FormLabel>
          <RadioGroup
            defaultValue={STATUSES[0].value}
            onChange={(e, v) => setStatus(v)}
          >
            {STATUSES.map((status, i) => (
              <FormControlLabel
                key={`status-key-${i}`}
                value={status.value}
                control={<Radio />}
                label={status.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {(status === "pro" || status === "secretary" || status === "tech") && (
          <FormControl fullWidth>
            <InputLabel>Établissement</InputLabel>
            <Select
              value={establishment}
              label="Établissement"
              onChange={(e, v) => setEstablishment(e.target.value)}
            >
              {establishments &&
                establishments.map((establishment, j) => (
                  <MenuItem
                    value={establishment.id}
                    key={`establishment-key-${j}`}
                  >
                    {`${establishment.name} (${establishment.type})`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <CustomButton
            title="Créer"
            onClick={() => submit()}
            disabled={!name || !email || !password || !status}
          />
        )}
      </Wrapper>
    </>
  );
}
