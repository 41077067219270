import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Tag from "../../Components/Tag";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import dayjs from "dayjs";
import CenteredDivider from "../../Components/CenteredDivider";
import { Typography } from "@mui/material";
import ScheduleContainer from "../../Components/Containers/ScheduleContainer";
import ScheduleItemContainer from "../../Components/Containers/ScheduleItemContainer";
import GroupByDate from "../../Utils/groupByDate";

export default function ProShowRoom() {
  const { id, name } = useLocation().state;
  // console.log(id);
  const { firebase } = useFirebase();
  const [schedule, setSchedule] = useState();

  useEffect(() => {
    (async () => {
      let minDate = new Date();
      let maxDate = new Date();
      maxDate.setDate(maxDate.getDate() - 15);
      let formatedMinDate = dayjs(minDate).format("YYYY-MM-DD");
      let formatedMaxDate = dayjs(maxDate).format("YYYY-MM-DD");

      const q = query(
        collectionGroup(firebase.db, "history"),
        where("roomId", "==", id),
        where("updatedAt", ">=", new Date(maxDate)),
        orderBy("updatedAt", "desc"),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      let temp = [];
      querySnapshot.forEach((tag) =>
        temp.push({
          versionId: tag.id,
          id: tag.ref.parent.parent.id,
          ...tag.data(),
        })
      );

      let ordered = GroupByDate(temp, formatedMinDate, formatedMaxDate);
      setSchedule(ordered);
    })();
  }, []);

  return (
    <>
      <Title title={`Suivit de la salle : ${name}`} align="center" />
      <>
        {schedule &&
          schedule.map((e, index) => {
            const date = [e][0][0];
            const data = [e][0][1];

            const formatedDate = dayjs(date).format("DD/MM/YYYY");

            return (
              <ScheduleContainer key={`schedule-container-${index}`}>
                <Typography variant="h5" color="primary">
                  {formatedDate}
                </Typography>
                {data.length ? (
                  <>
                    <CenteredDivider />
                    <ScheduleItemContainer>
                      {data.map((item) => (
                        <Tag tag={item} key={item.versionId} tagType="pro" />
                      ))}
                    </ScheduleItemContainer>
                  </>
                ) : (
                  ""
                )}
              </ScheduleContainer>
            );
          })}
      </>
    </>
  );
}
