import { Button } from "@mui/material";
import React from "react";

export default function CustomButton({
  title,
  onClick,
  backgroundColor = "primary",
  fullWidth = true,
  color = "primary",
  disabled = false,
}) {
  return (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      color={color}
      onClick={() => onClick()}
      sx={{ my: 1, mx: 1 }}
      style={{
        color: "#fff",
        backgroundColor: backgroundColor ? backgroundColor : "primary",
      }}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}
