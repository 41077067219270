import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenteredDivider from "../../Components/CenteredDivider";
import ScheduleItemContainer from "../../Components/Containers/ScheduleItemContainer";
import ScheduleContainer from "../../Components/Containers/ScheduleContainer";
import CustomButton from "../../Components/CustomButton";
import Tag from "../../Components/Tag";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import GroupByDate, { maxDate } from "../../Utils/groupByDate";
import { Delete, Edit } from "@mui/icons-material";
import { KITS_STATES } from "../../Utils/KitsStateFetch";
import FilterKitsByState from "../../Utils/FilterKitsByState";
import { debounce } from "../../Utils/Debounce";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditAlert = ({ title, fn, id, name }) => {
  const [text, setText] = useState();
  const [open, setOpen] = React.useState(false);

  
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomButton
        fullWidth={false}
        title={<Edit />}
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{name}</Typography>
          <TextField onChange={(e) => setText(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Non
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              fn(text, id);
            }}
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const DeleteAlert = ({ title, fn, id, name }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomButton
        fullWidth={false}
        title={<Delete />}
        color="secondary"
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Non
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              fn(id);
            }}
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function ShowPlace() {
  const { firebase } = useFirebase();
  const location = useLocation();
  const { id } = location.state;
  const [establishmentData, setEstablishmentData] = useState();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState();
  const navigate = useNavigate();
  const [establishmentName, setEstablishmentName] = useState();
  const [allTagsHistory, setAllTagsHistory] = useState();
  const [schedule, setSchedule] = useState();
  const [refresh, setRefresh] = useState(false);
  
  useEffect(() => {
    setInterval(() => {
      setRefresh(!refresh)
    }, 60000);
  }, [])


  // USE EFFECT 
  useEffect(() => {
    (async () => {
      try {
        const placeRef = doc(firebase.db, `places/${id}`);
        const placeData = await getDoc(placeRef);
        if (placeData.data().type === "Clinique") {
          setType("clinic");
          setEstablishmentName(placeData.data().name);
          const q = query(
            collectionGroup(firebase.db, "history"),
            where("placeId", "==", id),
            where("state", "in", KITS_STATES),
            where("updatedAt", ">=", new Date(maxDate)),
            orderBy("updatedAt", "desc"),
            limit(250)
          );

          // HISTORY ?
          const history = [];
          const historyQuerySnapshot = await getDocs(q);
          historyQuerySnapshot.forEach((tag) =>
            history.push({
              versionId: tag.id,
              id: tag.ref.parent.parent.id,
              ...tag.data(),
            })
          );
          const filteredHistory = FilterKitsByState(history);
          setAllTagsHistory(filteredHistory);
          const formatedHistory = GroupByDate(filteredHistory);
          setSchedule(formatedHistory);
          setLoading(false);
        } else {
          setType("place");
          const q = query(
            collectionGroup(firebase.db, "history"),
            where("placeId", "==", id),
            where("state", "in", KITS_STATES),
            where("updatedAt", ">=", new Date(maxDate)),
            orderBy("updatedAt", "desc"),
            limit(250)
          );
          const querySnapshot = await getDocs(q);
          let history = [];
          querySnapshot.forEach((tag) => {
            console.log(tag.data().state);
            history.push({
              versionId: tag.id,
              id: tag.ref.parent.parent.id,
              ...tag.data(),
            });
          });
          const filteredHistory = FilterKitsByState(history);
          setAllTagsHistory(filteredHistory);
          const formatedHistory = GroupByDate(filteredHistory);
          setSchedule(formatedHistory);
          setEstablishmentName(placeData.data().name);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        console.log("une erreure test");
      }
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      // LES SERVICES
      try {
        const servicesRef = collection(firebase.db, "services");
        const servicesQuery = query(
          servicesRef,
          where("place", "==", id),
          orderBy("name", "asc")
        );
        const servicesSnapshot = await getDocs(servicesQuery);
        let services = [];
        for (const service of servicesSnapshot.docs) {
          let rooms = [];
          const roomsRef = collection(firebase.db, "places");
          const roomsQuery = query(
            roomsRef,
            where("service", "==", service.id),
            orderBy("name", "asc")
          );
          const roomsSnapshot = await getDocs(roomsQuery);
          roomsSnapshot.forEach((room) => {
            rooms.push({
              id: room.id,
              ...room.data(),
              serviceName: service.data().name,
            });
          });
          rooms.sort((a, b) => a.name.localeCompare(b.name));
          const data = {
            id: service.id,
            ...service.data(),
            rooms: rooms,
          };
          //ENVOIE LA DONN2ES
          services.push(data);
        }
        const establishment = { services: services };
        // const [establishmentData, setEstablishmentData] = useState();
        setEstablishmentData(establishment);
      } catch (e) {
        console.log();
      }
    })();
  }, [refresh]);

  const filterRooms = debounce((e) => {
    const { value } = e.target;
    let temp = [];
    if (!value) {
      setSchedule(GroupByDate(allTagsHistory));
      return;
    }
    allTagsHistory.forEach(
      (tag) =>
        tag?.roomName?.toLowerCase()?.includes(value?.toLowerCase()) &&
        temp.push(tag)
    );
    setSchedule(GroupByDate(temp));
  });

  const handleNavigation = (id, name) =>
    navigate("/showRoom", {
      state: {
        id: id,
        name: name,
      },
    });

  const editServiceName = async (value, id) => {
    try {
      const serviceRef = doc(firebase.db, `services/${id}`);
      updateDoc(serviceRef, { name: value });
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };

  const editRoomName = async (value, id) => {
    try {
      const roomRef = doc(firebase.db, `places/${id}`);
      const roomTagRef = doc(firebase.db, `tags/${id}`);
      updateDoc(roomTagRef, { name: value });
      updateDoc(roomRef, { name: value });
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteRoom = async (id) => {
    try {
      const roomRef = doc(firebase.db, `places/${id}`);
      const roomTagRef = doc(firebase.db, `tags/${id}`);
      deleteDoc(roomTagRef);
      deleteDoc(roomRef);
      setRefresh(!refresh);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading ? (
        <LinearProgress style={{ marginBlock: 20 }} />
      ) : (
        <>
          {type === "clinic" && establishmentName && (
            <>
              <Title title={establishmentName} align="center" />
              <CenteredDivider />
              <TextField
                label="Rechercher une chambre"
                variant="outlined"
                style={{
                  margin: "auto",
                  display: "flex",
                  width: "90%",
                  marginTop: 15,
                }}
                onChange={filterRooms}
              />
              {schedule &&
                schedule.map((e, index) => {
                  const date = [e][0][0];
                  const data = [e][0][1];

                  const formatedDate = dayjs(date).format("DD/MM/YYYY");
                  // LES CARDS
                  return (
                    <ScheduleContainer key={`schedule-container-${index}`}>
                      <Typography variant="h5" color="primary">
                        {formatedDate}
                      </Typography>
                      {data.length ? (
                        <>
                          <CenteredDivider />
                          <ScheduleItemContainer>
                            {data.map((item, i) => (
                              <>
                                <Tag
                                  tag={item}
                                  key={item.versionId}
                                  iterateur={i}
                                />
                              </>
                            ))}
                          </ScheduleItemContainer>
                        </>
                      ) : (
                        ""
                      )}
                    </ScheduleContainer>
                  );
                })}
              {establishmentData &&
                establishmentData.services.map((service) => {
                  const { id: serviceId, name: serviceName, rooms } = service;
                  return (
                    <div
                      style={{ width: "90%", margin: "auto" }}
                      key={serviceId}
                    >
                      <Row>
                        <div>
                          <Title title={serviceName} variant="h4" />
                        </div>
                        <div>
                          <EditAlert
                            fn={editServiceName}
                            id={serviceId}
                            name={serviceName}
                            title="Voulez vous modifier le nom du service ?"
                          />
                        </div>
                      </Row>
                      <CenteredDivider />
                      {rooms.length > 0 &&
                        rooms.map((room) => {
                          const { id: roomId, name: roomName } = room;
                          return (
                            <React.Fragment key={roomId}>
                              <Row>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleNavigation(roomId, roomName)
                                    }
                                  >
                                    <Title title={room.name} variant="h5" />
                                  </Button>
                                </div>
                                <div>
                                  <CustomButton
                                    fullWidth={false}
                                    title="Accèder à la chambre"
                                    onClick={() =>
                                      handleNavigation(roomId, roomName)
                                    }
                                  />
                                  <EditAlert
                                    fn={editRoomName}
                                    id={roomId}
                                    name={roomName}
                                    title="Voulez vous modifier le nom de la chambre ?"
                                  />
                                  <DeleteAlert
                                    fn={deleteRoom}
                                    id={roomId}
                                    name={roomName}
                                    title="Voulez vous supprimer la chambre ?"
                                  />
                                </div>
                              </Row>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  );
                })}
            </>
          )}
          {type === "place" && schedule && (
            <>
              <Title title={establishmentName} align="center" />
              {/* <TextField
                label="Rechercher une chambre"
                variant="outlined"
                style={{
                  margin: "auto",
                  display: "flex",
                  width: "90%",
                  marginTop: 15,
                }}
                onChange={filterRooms}
              /> */}
              {schedule &&
                schedule.map((e, index) => {
                  const date = [e][0][0];
                  const data = [e][0][1];

                  const formatedDate = dayjs(date).format("DD/MM/YYYY");

                  return (
                    <ScheduleContainer key={`container-key-${index}`}>
                      <Typography variant="h5" color="primary">
                        {formatedDate}
                      </Typography>
                      {data.length ? (
                        <>
                          <CenteredDivider />
                          <ScheduleItemContainer>
                            {data.map((item) => (
                              <Tag
                                tag={item}
                                key={item.versionId}
                                // exportTag={false}
                              />
                            ))}
                          </ScheduleItemContainer>
                        </>
                      ) : null}
                    </ScheduleContainer>
                  );
                })}
            </>
          )}
        </>
      )}
    </>
  );
}
