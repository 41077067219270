import styled from "@emotion/styled";
import { Card } from "@mui/material";
import React from "react";
const Container = styled(Card)`
  border-radius: 30px;
  &:hover {
    cursor: pointer;
    box-shadow: 5px 10px 15px rgba(100, 100, 100, 0.4);
  }
`;
export default function CardContainer({ children, onClick = () => {} }) {
  return (
    <Container elevation={4} onClick={() => onClick()}>
      {children}
    </Container>
  );
}
