import React, { createContext, useContext } from "react";
import Firebase from "./Firebase";

export const FirebaseContext = createContext();
export const useFirebase = () => useContext(FirebaseContext);

const FirebaseContextProvider = (props) => {
  const firebase = new Firebase();
  return (
    <FirebaseContext.Provider value={{ firebase }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
