import styled from "@emotion/styled";
import React from "react";
const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin: 1rem 2vw;
`;
export default function ScheduleItemContainer({ children }) {
  return <ItemContainer>{children}</ItemContainer>;
}
