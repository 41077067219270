import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LeftMenu from "./LeftMenu";
import { useNavigate } from "react-router-dom";
import { decode } from "../../Utils/Encode";

export default function Header() {
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear();
    navigate("/login");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {decode(sessionStorage.status) !== "tech" && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setIsLeftMenuOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <div style={{ flex: 1 }}>
            <Button
              style={{ color: "#FFFFFF", fontSize: 18 }}
              onClick={() => {
                decode(sessionStorage.status) === "admin"
                  ? navigate("/home")
                  : navigate("/dashboard");
              }}
            >
              LYTA-CONNECT
            </Button>
          </div>
          <Button style={{ color: "#FFFFFF", fontSize: 18 }} onClick={logout}>
            Deconnexion
          </Button>
        </Toolbar>
      </AppBar>
      <LeftMenu open={isLeftMenuOpen} switchMenu={setIsLeftMenuOpen}></LeftMenu>
    </Box>
  );
}
