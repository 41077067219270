import styled from "@emotion/styled";
import React from "react";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem;
`;

export default function TagsContainer({ children }) {
  return <Container>{children}</Container>;
}
