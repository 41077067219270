import styled from "@emotion/styled";
import {
  Business,
  Download,
  Home,
  Inbox,
  Label,
  AccountCircle,
} from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decode } from "../../Utils/Encode";

const ListContainer = styled.div`
  width: 250px;
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
`;

export default function LeftMenu({ open, switchMenu }) {
  const navigate = useNavigate();
  const [state, setState] = useState({ left: false });
  useEffect(() => {
    setState({ ...state, left: open });
  }, [open]);

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = () => (
    <ListContainer role="presentation">
      <Title>Menu</Title>
      {decode(sessionStorage.status) === "admin" && (
        <List>
          <ListItem
            button
            onClick={() => {
              navigate("/home");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Accueil" />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              navigate("/listPlaces");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            <ListItemText primary="Liste des établissements" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/listKits");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Inbox />
            </ListItemIcon>
            <ListItemText primary="Liste des kits" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/listTags");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Label />
            </ListItemIcon>
            <ListItemText primary="Liste des puces" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/exportKits");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText primary="Exporter des kits" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/createUser");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Créer un utilisateur" />
          </ListItem>
        </List>
      )}
      {decode(sessionStorage.status) === "pro" && (
        <List>
          <ListItem
            button
            onClick={() => {
              navigate("/pro/dashboard");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>
      )}
      {decode(sessionStorage.status) === "secretary" && (
        <List>
          <ListItem
            button
            onClick={() => {
              navigate("/pro/dashboard");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/exportKits");
              switchMenu(false);
            }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText primary="Exporter des kits" />
          </ListItem>
        </List>
      )}
    </ListContainer>
  );

  return (
    <Drawer
      open={state.left}
      onClose={() => {
        toggleDrawer("left", false);
        switchMenu(false);
      }}
    >
      {sideList()}
    </Drawer>
  );
}
