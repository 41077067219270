import styled from "@emotion/styled";
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import CenteredDivider from "../../Components/CenteredDivider";
import Tag from "../../Components/Tag";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import FilterKitsByState from "../../Utils/FilterKitsByState";
import { maxDate } from "../../Utils/groupByDate";
import { KITS_STATES } from "../../Utils/KitsStateFetch";

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 2vw;
`;

export default function ExportKits() {
  const { firebase } = useFirebase();
  const [kits, setKits] = useState();

  useEffect(() => {
    (async () => {
      const q = query(
        collectionGroup(firebase.db, "history"),
        where("state", "in", KITS_STATES),
        where("updatedAt", ">=", new Date(maxDate)),
        orderBy("updatedAt", "desc"),
        limit(250)
      );
      const querySnapshot = await getDocs(q);
      let temp = [];
      querySnapshot.forEach((tag) =>
        temp.push({
          versionId: tag.id,
          id: tag.ref.parent.parent.id,
          ...tag.data(),
        })
      );
      const filtered = FilterKitsByState(temp);
      setKits(filtered);
    })();
  }, []);

  return (
    <>
      <Title title={`Export de kits`} align="center" />
      <>
        <CenteredDivider />
        <ItemContainer>
          {kits &&
            kits.map((kit, i) => (
              <Tag tag={kit} key={`${kit.versionId}-${i}`} />
            ))}
        </ItemContainer>
      </>
    </>
  );
}
