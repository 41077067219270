import React, { useEffect, useState } from "react";
import { Box, CardContent, LinearProgress, Typography } from "@mui/material";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CardContainer from "./Containers/CardContainer";
import CustomButton from "./CustomButton";
import ExpressAPI from "../Utils/ExpressAPI";

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledCardContent = styled(CardContent)`
  &:last-child {
    padding-bottom: 10px
  },
`;

const SEEN_COLOR = "#00ff00";
export default function Tag({
  tag,
  exportTag = true,
  tagType = "admin",
  allowRedirect = true,
  iterateur
}) {
  const {
    belongsTo,
    createdAt,
    id,
    name,
    place,
    placeId,
    prescription,
    state,
    subType,
    type,
    updatedAt,
    updatedBy,
    versionId,
    seen,
    roomName,
    serviceName,
  } = tag;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const seenStorage = sessionStorage.getItem(versionId);
  const [color, setColor] = useState(seenStorage || seen ? SEEN_COLOR : null);
  const handleClick = () => {
    navigate(tagType === "admin" ? "/tagHistory" : "/pro/kitHistory", {
      state: {
        id: id,
      },
    });
  };
  const [link, setLink] = useState("")

  useEffect(() => {
    if(iterateur < 5){
      downloadSummary({type : true})
    }
  }, [iterateur])


  //information de la 
  const adminData = {
    name: {
      key: "Nom",
      data: name,
    },
    // createdAt: {
    //   key: "Date de création",
    //   data: dayjs.unix(createdAt.seconds).format("DD/MM/YYYY à HH:mm"),
    // },
    room: {
      key: "Chambre",
      data: roomName,
    },
    service: {
      key: "Service",
      data: serviceName,
    },
    place: {
      key: "Emplacement",
      data: place,
    },
    type: {
      key: "Type",
      data: subType,
    },
    status: {
      key: "Statut",
      data: state,
    },
    updatedAt: {
      key: "Horaire",
      data: tag?.updatedAt?.seconds
        ? dayjs.unix(updatedAt.seconds).format("DD/MM/YYYY à HH:mm")
        : null,
    },
    updatedBy: {
      key: `${state} par`,
      data: updatedBy,
    },
  };

  const downloadSummary = async ({type = false}) => {
    try {
      setLoading(true);
      const res = await ExpressAPI(
        "GET",
        `pdf/getKitSummary/${id}/${versionId}`,
        [],
        "blob"
      );

      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.id = "div-" + iterateur;
      link.href = fileURL;
      link.setAttribute("download", `${id}.pdf`);
      document.body.appendChild(link);
      console.log(link)
      if(!type){
        link.click();
        link.parentNode.removeChild(link);
        sessionStorage.setItem(versionId, 1);
        setColor(SEEN_COLOR);
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const statesConditions =
    state === "Déposé au laboratoire" ||
    state === "Pris en charge par laboratoire" ||
    state === "À récupérer" ||
    state === "Prélevé" ||
    state === "Réceptionné";

  return (
    <TagContainer>
      <CardContainer onClick={() => allowRedirect && handleClick()}>
        <StyledCardContent>
          {
            //tagType === "admin" &&
            Object.keys(adminData).map(
              (item, index) =>
                adminData[item].data &&
                adminData[item] && (
                  <Box pb={1} key={"field-key-" + index}>
                    <Typography
                      color={color ? color : "primary"}
                      display="inline"
                    >
                      {adminData[item].key} :
                    </Typography>
                    <Typography display="inline" style={{ paddingLeft: 10 }}>
                      {adminData[item].data}
                    </Typography>
                  
                  </Box>
                )
            )
          }
          {/* {tagType === "pro" &&
            Object.keys(proData).map((item, index) => {
              return (
                proData[item].data &&
                proData[item] && (
                  <Box pb={1} key={"field-key-" + index}>
                    <Typography
                      color={color ? color : "primary"}
                      display="inline"
                    >
                      {proData[item].key} :
                    </Typography>
                    <Typography display="inline" style={{ paddingLeft: 10 }}>
                      {proData[item].data}
                    </Typography>
                  </Box>
                )
              );
            })} */}
        </StyledCardContent>
      </CardContainer>
      {/* Button de telechargement  */}
      {exportTag && statesConditions &&  (
        <>
          {loading ? (
            <LinearProgress />
          ) : (
            <CustomButton
              title="Récapitulatif"
              onClick={() => {
                if(iterateur < 5){
                  const link = document.getElementById("div-"+iterateur);
                  link.click();
                  //link.parentNode.removeChild(link);
                  sessionStorage.setItem(versionId, 1);
                  setColor(SEEN_COLOR);
                }else{
                  downloadSummary({type : false})
                }
              }}
              backgroundColor={color && color}
            />
          )}
        </>
      )}
  
    </TagContainer>
  );
}
