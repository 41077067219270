import React from "react";
import { TextField } from "@mui/material";

export default function CustomTextInput({
  required = true,
  label = "",
  defaultValue = "",
  setState = () => {},
  autoFocus = false,
  margin = "normal",
  type = "text",
}) {
  return (
    <TextField
      margin={margin}
      required={required}
      label={label}
      fullWidth
      defaultValue={defaultValue && defaultValue}
      onBlur={(e) => setState(e.target.value)}
      autoFocus={autoFocus}
      type={type}
    />
  );
}
