import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CenteredDivider from "../../Components/CenteredDivider";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";
import ExpressAPI from "../../Utils/ExpressAPI";

const Data = ({ children }) => {
  return <Box sx={{ display: "flex", alignItems: "baseline" }}>{children}</Box>;
};
const Key = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  width:200px;
  `
);
const Container = styled.div`
  padding: 20px;
  margin: 20px;
`;
export default function ProKitHistory() {
  const { id } = useLocation().state;
  const { firebase } = useFirebase();
  const [history, setHistory] = useState();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  useEffect(() => {
    (async () => {
      try {
        const historyRef = collection(firebase.db, `tagsHistory/${id}/history`);
        const q = query(historyRef, orderBy("updatedAt", "desc"), limit(50));
        const querySnapshot = await getDocs(q);
        let historyArr = [];
        querySnapshot.forEach((doc) => {
          const formatedDoc = {
            id: doc.id,
            patientId: doc.data().patientId,
            prescription: doc.data().prescription,
            seen: doc.data().seen ? true : false,
            state: {
              key: "Status",
              data: doc.data().state,
            },
            updatedBy: {
              key: "Par",
              data: doc.data().updatedBy,
            },
            updatedAt: {
              key: "Horaire",
              data: dayjs
                .unix(doc.data().updatedAt.seconds)
                .format("DD/MM/YYYY à HH:mm"),
            },
            place: {
              key: "Emplacement",
              data: doc.data().place ? doc.data().place : "En transit",
            },
            patient: {
              key: "Patient",
              data: doc.data().patient,
            },
          };
          historyArr.push(formatedDoc);
        });
        const placeRef = doc(firebase.db, `places/${id}`);
        const name = await getDoc(placeRef);
        setName(name?.data()?.name ? name?.data()?.name : historyArr[0].name);
        setHistory(historyArr);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const downloadDocument = (prescription) => {
    getDownloadURL(ref(getStorage(), prescription))
      .then((imgUrl) => {
        window.open(imgUrl, "_blank");
      })
      .catch((e) => console.log(e));
  };

  const downloadSummary = async (versionId) => {
    console.log(id);
    try {
      ExpressAPI("GET", `pdf/getKitSummary/${id}/${versionId}`, [], "blob")
        .then((res) => {
          const file = new Blob([res.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `${id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          sessionStorage.setItem(versionId, 1);
        })
        .catch((e) => {
          console.log(e);
          // setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Title
        title={`Historique du tag${name ? ` : ${name}` : ""}`}
        align="center"
      />
      {!loading ? (
        history &&
        history.map((entry) => {
          let seen = sessionStorage.getItem(entry.id) || entry.seen;
          // console.log(seen);
          return (
            <React.Fragment key={entry.id}>
              <Container>
                {Object.keys(entry).map((item, index) => {
                  return (
                    entry[item]?.data &&
                    entry[item] && (
                      <Data key={index}>
                        <Key variant="h6" style={{ color: seen && "#00ff00" }}>
                          {entry[item].key} :{" "}
                        </Key>
                        <Typography>{entry[item].data}</Typography>
                      </Data>
                    )
                  );
                })}
                {entry.prescription && (
                  <Data>
                    <Key variant="h6" style={{ color: seen && "#00ff00" }}>
                      Document :{" "}
                    </Key>
                    <Button
                      onClick={() => downloadDocument(entry.prescription)}
                      variant="contained"
                      style={{ backgroundColor: seen && "#00ff00" }}
                    >
                      Document
                    </Button>
                  </Data>
                )}
                {entry.state.data === "Déposé au laboratoire" && (
                  <Data>
                    <Key variant="h6" style={{ color: seen && "#00ff00" }}>
                      Récapitulatif :{" "}
                    </Key>
                    <Button
                      onClick={() => downloadSummary(entry.id)}
                      variant="contained"
                      style={{ backgroundColor: seen && "#00ff00" }}
                    >
                      Récupérer le récapitulatif
                    </Button>
                  </Data>
                )}
              </Container>
              <CenteredDivider />
            </React.Fragment>
          );
        })
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
