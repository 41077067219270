import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDo02kcB_c0-9zy9pheIcEo-ecQi18dKqA",
  authDomain: "lyta-connect.firebaseapp.com",
  projectId: "lyta-connect",
  storageBucket: "lyta-connect.appspot.com",
  messagingSenderId: "384513278908",
  appId: "1:384513278908:web:3b9335554fcd07432061b9",
};

class Firebase {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.db = getFirestore();
    onAuthStateChanged(this.auth, (user) => {
      this.currentUser = user;
    });
  }
}

export default Firebase;
