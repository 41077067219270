import styled from "@emotion/styled";
import { Biotech, CorporateFare, LocalHospital } from "@mui/icons-material";
import { CardContent, LinearProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CenteredDivider from "../../Components/CenteredDivider";
import CardContainer from "../../Components/Containers/CardContainer";
import TagsContainer from "../../Components/Containers/TagsContainer";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBiotech = styled(Biotech)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 75px
  `
);

const StyledLocalHospital = styled(LocalHospital)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 75px
  `
);

const StyledCorporateFare = styled(CorporateFare)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 75px
  `
);

export default function ListPlaces() {
  const { firebase } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const placesRef = collection(firebase.db, "places");
        const q = query(placesRef, orderBy("name", "asc"));
        const querySnapshot = await getDocs(q);
        let tempPlaces = [];
        querySnapshot.forEach((place) => {
          if (place.data().type !== "Chambre") {
            tempPlaces.push({ id: place.id, ...place.data() });
          }
        });
        setPlaces(tempPlaces);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Title title="Liste des établissements" align="center" />

      <CenteredDivider />
      {loading ? (
        <LinearProgress />
      ) : (
        <TagsContainer>
          {places &&
            places.map((place, i) => {
              const { id, name, type, createdAt } = place;
              const time = dayjs
                .unix(createdAt.seconds)
                .format("DD/MM/YYYY à HH:mm");
              return (
                <CardContainer
                  key={`place-uid-${i}`}
                  onClick={() =>
                    navigate("/showPlace", {
                      state: {
                        id: id,
                        name: name,
                      },
                    })
                  }
                >
                  <CardContent>
                    <IconContainer>
                      {type === "Laboratoire" && <StyledBiotech />}
                      {type === "Clinique" && <StyledLocalHospital />}
                      {type !== "Laboratoire" && type !== "Clinique" && (
                        <StyledCorporateFare />
                      )}
                    </IconContainer>
                    <Typography variant="h6" color="primary">
                      {name}
                    </Typography>
                    <Typography>{type}</Typography>
                    <Typography>Crée le : {time}</Typography>
                  </CardContent>
                </CardContainer>
              );
            })}
        </TagsContainer>
      )}
    </>
  );
}
