import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
//export const APIURL = "http://localhost:8000/";
export const APIURL = "https://back-lyta-connect.herokuapp.com/";

export const axiosInstance = axios.create({
  baseURL: APIURL,
  timeout: 8000,
  responseType: "json",
});

export default async function ExpressAPI(
  method = "GET",
  url = "/",
  data = undefined,
  responseType = "json"
) {
  try {
    return await axios({
      method: method,
      url: APIURL + url,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      responseType: responseType,
    });
  } catch (e) {
    if (e.response.status === 401) {
      alert("Veuillez vous reconnecter");
      sessionStorage.clear();
      signOut(getAuth());
      window.location.reload();
    }
  }
}
