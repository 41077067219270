import styled from "@emotion/styled";

const Wrapper = styled.div`
  margin: 1rem;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px 3px rgba(100, 100, 100, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 10px;
`;

export default function ScheduleContainer({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
