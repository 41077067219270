import { LinearProgress } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import TagsContainer from "../../Components/Containers/TagsContainer";
import Tag from "../../Components/Tag";
import { useFirebase } from "../../Firebase/FirebaseContext";

export default function ListTags() {
  const { firebase } = useFirebase();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const tagsRef = collection(firebase.db, "tags");
        const q = query(tagsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        let tagsArr = [];
        querySnapshot.forEach((doc) =>
          tagsArr.push({
            id: doc.id,
            name: doc.data().name,
            createdAt: doc.data().createdAt,
            type: doc.data().type,
            subType: doc.data().subType,
          })
        );
        setTags(tagsArr);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div>
      {!loading ? (
        <TagsContainer>
          {tags.length > 0 && tags.map((tag) => <Tag tag={tag} key={tag.id} />)}
        </TagsContainer>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}
