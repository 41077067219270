import { LinearProgress } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ScheduleItemContainer from "../../Components/Containers/ScheduleItemContainer";
import Tag from "../../Components/Tag";
import Title from "../../Components/Title";
import { useFirebase } from "../../Firebase/FirebaseContext";

export default function TagHistory() {
  const { id } = useLocation().state;
  const { firebase } = useFirebase();
  const [history, setHistory] = useState();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  useEffect(() => {
    (async () => {
      try {
        const historyRef = collection(firebase.db, `tagsHistory/${id}/history`);
        const q = query(historyRef, orderBy("updatedAt", "desc"), limit(50));
        const querySnapshot = await getDocs(q);
        let historyArr = [];
        querySnapshot.forEach((tag) => {
          historyArr.push({
            versionId: tag.id,
            id: tag.ref.parent.parent.id,
            ...tag.data(),
          });
        });
        const placeRef = doc(firebase.db, `places/${id}`);
        const name = await getDoc(placeRef);
        setName(name?.data()?.name ? name?.data()?.name : historyArr[0].name);
        setHistory(historyArr);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Title
        title={`Historique du tag${name ? ` : ${name}` : ""}`}
        align="center"
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <ScheduleItemContainer>
          {history &&
            history.map((entry) => <Tag tag={entry} allowRedirect={false} />)}
        </ScheduleItemContainer>
      )}
    </>
  );
}
